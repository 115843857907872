<template>
  <div>
    <full-page ref="fullpage" :options="options" id="fullpage">
      <!-- <div class="section" data-id="story1-1.1.1-1">
        <div class="fp-bg"></div>
        <div class="flex h-screen justify-center items-center">
          <div class="bg-orange p-3 w-full text-center">First section ...</div>
        </div>
      </div>
      <div class="section" data-id="story1-1.1.1-2">
        <div class="fp-bg"></div>
        <div class="bg-red-500 p-3">Second section Jakob Lipps ...</div>
      </div> -->
      <!-- intro.0.1 Graphic of three Characters Situation on the globe, we subsequently zoom in to each one  -->

      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex h-screen justify-center items-center">
          <div class="text-center">
            <h1 class="uppercase text-xl md:text-5xl">Momentum</h1>
            <h2 class="text-lg md:text-xl p-2 md:p-4">
              Towards human-centered drug policies
            </h2>
            <p class="p-2 text-sm md:text-xl">
              The EU Drugs Strategy 2021-2025 is an important milestone in the
              global momentum towards decent drug policies and sustainable
              development. It aims to counter the global drug trade by
              empowering people to exit the illicit economy. Drug policy affects
              the lives of millions of people worldwide. Discover the stories of
              Ana, Mila and Kofi* and find out how effective human-oriented drug
              policies can be.<br />*The fictional characters are based on true
              interviews and events.
            </p>
            <p class="p-2 md:p-4 text-sm md:text-xl">Turn up your speakers.</p>
            <div class="">
              <img
                src="~@/assets/images/stories/intro/Icons/01.Headset.svg"
                alt="Story image"
                class="w-1/3 mx-auto"
              />
            </div>
            <p class="p-4 md:text-xl">Scroll down!</p>
          </div>
        </div>
      </fullpage-section>
      <!-- intro.0.1  -->
      <!-- intro.0.1  Ana -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header relative">
          <h3 class="text-center pt-6 pb-6 bg-orange text-5xl">Ana</h3>
          <div class="items-center bg-orange">
            <div class="mb-10 w-1/2 mx-auto">
              <svg-01-clocks-fullsize-ana
                ref="svg01clocksfullsizeana"
                :key="svg01ClocksFullsizeAnaVal"
              ></svg-01-clocks-fullsize-ana>
            </div>
          </div>
          <InitialLetterParagraph
            text="At first, the coca brought us income. But with the money came violence, violence on all fronts. Every morning I woke up terrified. I never knew what the day would bring. How did I end up there? With one foot I was in the grave, with the other in prison. "
            :background="['bg-blue']"
            :parentBackground="'bg-orange'"
          ></InitialLetterParagraph>
          <audio-element
            class="mt-10"
            ref="audio01"
            :reference="'audio01'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_0.1.wav')
            "
          ></audio-element>
        </div>
      </fullpage-section>
      <!-- intro.0.1  -->
      <!-- intro.0.1 Kofi  -->
      <fullpage-section :wrapperClasses="['bg-yellow']">
        <div class="flex flex-col h-screen-without-header relative">
          <h3 class="text-center pt-6 pb-6 bg-yellow text-5xl">Kofi</h3>
          <div class="items-center bg-yellow">
            <div class="mb-10 w-1/2 mx-auto">
              <svg-01-clocks-fullsize-kofi
                ref="svg01clocksfullsizekofi"
                :key="svg01ClocksFullsizeKofiVal"
              ></svg-01-clocks-fullsize-kofi>
            </div>
          </div>
          <InitialLetterParagraph
            text="The worst moment is when you hit rock bottom and realise: this one thing in your life controls everything…it is your income, your happiness. Everything. I don’t want this life for my children."
            :background="['bg-blue']"
            :parentBackground="'bg-yellow'"
          ></InitialLetterParagraph>
          <!-- <audio-element
            class="mt-10"
            ref="audio11111"
            :reference="'audio11111'"
            :src="
              require('../../assets/audio/stories/story_1/Sounds_Voice-Overs/Audio Ana_1.1.1.wav')
            "
          ></audio-element> -->
        </div>
      </fullpage-section>
      <!-- intro.0.1  -->
      <!-- intro.0.1  Mila -->
      <fullpage-section :wrapperClasses="['bg-pink-600']">
        <div class="flex flex-col h-screen-without-header relative">
          <h3 class="text-center pt-6 pb-6 text-5xl">Mila</h3>
          <div class="items-center">
            <div class="mb-10 w-1/2 mx-auto">
              <svg-01-clocks-fullsize-mila
                ref="svg01clocksfullsizemila"
                :key="svg01ClocksFullsizeMilaVal"
              ></svg-01-clocks-fullsize-mila>
            </div>
          </div>
          <InitialLetterParagraph
            text="Death started circling around me, pulling me in. A girl I knew passed away and that shook me up. I’ve always avoided my fears but at some point, I had to face them. "
            :background="['bg-blue']"
            :parentBackground="'bg-pink-600'"
          ></InitialLetterParagraph>
          <audio-element
            class="mt-10"
            ref="audio03"
            :reference="'audio03'"
            :src="
              require('../../assets/audio/stories/story_3/Sounds_Voice-Overs/Audio Mila_0.1.mp3')
            "
          ></audio-element>
        </div>
      </fullpage-section>
      <!-- intro.0.1  -->
      <!-- intro.0.2  We see the globe again, three blinking points to mark our three narratives -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <p class="p-4">
          Three continents, three stories – all part of the same vicious cycle.
          Learn how the stories of Ana, Kofi and Mila connect. Explore how their
          lives could be transformed.
        </p>
        <div class="text-center">
          <svg-01-globe-animated
            class="w-11/12 m-auto transform scale-100"
            ref="svg01globeanimated"
            :key="svg01GlobeAnimatedVal"
          ></svg-01-globe-animated>
        </div>
      </fullpage-section>
      <!-- intro.0.2  -->
      <!-- intro.0.3  Zoom to South America, West Africa, Europe -->

      <fullpage-section :wrapperClasses="['bg-green-200']">
        <p class="p-4">
          Illicit drug crop cultivation - especially coca and opium - has
          continued to rise over the last 10 years. This is in response to a
          steady increase in global drug use.<br /><br />
          In 2020, around 275 million people used drugs, up by 22 per cent from
          2010. This is set to continue to rise in the future, especially on the
          African continent. (enact - Drug demand and use in Africa)<br /><br />In
          Europe alone, more than 200 tons of cocaine were seized in 2019, up
          from a little over 50 tons ten years earlier. (Figure 3 Europol
          report, p. 13)
        </p>
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2A.svg"
          alt="story image"
          class="w-1/6 mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.1/infographic_0.3.1A-01.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.1/infographic_0.3.1C-01.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.1/infographic_0.3.1D-01.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2B_1.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2B_2.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2B_3.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2B_4.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.2/infographic_0.3.2B_5.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <img
          src="~@/assets/images/stories/intro/Story_1_infographic_0.3.1/infographic_0.3.1D-01.svg"
          alt="story image"
          class="mx-auto mt-20"
        />
      </fullpage-section>
      <!-- intro.0.3  -->
      <!-- intro.0.4 Globe fades into the middle of this graph: -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <p class="p-4">
          The international illicit drug trade relies on the exploitation of
          people. The supply chain is complex and involves many actors.<br /><br />
          From cultivation to drug use, the system relies on people who depend
          on it. For farmers it is often their only income. At the other end
          distributors sell the product to people who use drugs. <br /><br />How
          do we break this vicious cycle? <br /><br />A policy that moves away
          from merely focusing on substances, their seizure and destruction is
          needed. <br /><br />A policy that focuses on the people and
          communities affected. <br /><br />A policy that creates alternatives
          and gives people real choices.
        </p>
      </fullpage-section>
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="items-center">
          <svg-0423A
            class="w-11/12 md:w-1/2 m-auto"
            ref="svg0423A"
            :key="svg0423AVal"
          ></svg-0423A>
        </div>
      </fullpage-section>

      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div class="flex h-screen-without-header justify-center items-center">
          <div class="">
            <!-- ⬅️ THIS DIV WILL BE CENTERED -->
            <svg-0423B
              class="w-11/12 m-auto"
              ref="svg0423B"
              :key="svg0423BVal"
            ></svg-0423B>
          </div>
        </div>
      </fullpage-section>

      <!-- intro.0.4  -->
      <!-- intro.0.5  -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header p-5">
          <p class="pt-10">
            The European Union Drug Strategy 2021-2025 is a global signpost. It
            empowers people to build a dignified life beyond the illicit
            economies.
          </p>
          <p>It focuses on three main areas:</p>
          <ul class="list-decimal p-8">
            <li>reducing drug supply and tackling drug-related crime</li>
            <li>reducing drug demand and protecting public health</li>
            <li>
              preventing the potential risks and harm caused by drugs for users
              and society
            </li>
          </ul>
          <p>
            It also promotes a human-centred development approach in regions
            where illicit drugs are cultivated, also known as Alternative
            Development (AD).
          </p>
        </div>
      </fullpage-section>
      <!-- intro.0.5  -->
      <!-- intro.0.6 1 -->
      <fullpage-section :wrapperClasses="['bg-orange']">
        <div class="flex flex-col h-screen-without-header p-5">
          <p class="pt-10">
            Explore the potential of this shift in drug policy…
          </p>
          <p>
            A shift that centres on the human rights of those whose lives have
            been ravaged by the illicit drug economy.
          </p>
          <p>
            See how it changes the lives of affected individuals and communities
            by listening to the stories of Ana, Kofi, and Mila.
          </p>
        </div>
      </fullpage-section>
      <!-- intro.0.6 1 -->
      <!-- intro.0.6 Globe fades into the middle of this graph: -->
      <fullpage-section :wrapperClasses="['bg-green-200']">
        <div
          class="
            flex flex-col
            h-screen-without-header
            justify-center
            items-center
          "
        >
          <p class="p-4 md:text-xl">
            Click on a clock to select the story you want to begin with.
          </p>
          <div class="mt-4 grid grid-cols-3 gap-4">
            <router-link to="/story-ana">
              <img
                src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Ana.svg"
                alt="story image"
                class="w-full px-2 md:w-2/3 mx-auto"
              />
              <h3 class="text-center text-2xl pb-2">Ana</h3>
            </router-link>
            <router-link to="/story-kofi">
              <img
                src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Kofi.svg"
                alt="story image"
                class="w-full px-2 md:w-2/3 mx-auto"
              />
              <h3 class="text-center text-2xl pb-2">Kofi</h3>
            </router-link>
            <router-link to="/story-mila">
              <img
                src="~@/assets/images/stories/intro/Intro_01_Clocks/01.Clocks_fullsize_Mila.svg"
                alt="story image"
                class="w-full px-2 md:w-2/3 mx-auto"
              />
              <h3 class="text-center text-2xl pb-2">Mila</h3>
            </router-link>
          </div>
        </div>
      </fullpage-section>
      <!-- intro.0.6  -->
    </full-page>
  </div>
</template>

<script>
import FullpageSection from "../FullpageSection";
import Svg0423A from "../svg/Svg0423A";
import Svg0423B from "../svg/Svg0423B";
import Svg01ClocksFullsizeAna from "../svg/Svg01ClocksFullsizeAna";
import Svg01ClocksFullsizeKofi from "../svg/Svg01ClocksFullsizeKofi";
import Svg01ClocksFullsizeMila from "../svg/Svg01ClocksFullsizeMila";
import Svg01GlobeAnimated from "../svg/Svg01GlobeAnimated";
import InitialLetterParagraph from "../InitialLetterParagraph";
import AudioElement from "../AudioElement.vue";

export default {
  name: "IntroFullpageWrapper",
  components: {
    FullpageSection,
    Svg0423A,
    Svg0423B,
    Svg01ClocksFullsizeAna,
    Svg01ClocksFullsizeKofi,
    Svg01ClocksFullsizeMila,
    Svg01GlobeAnimated,
    InitialLetterParagraph,
    AudioElement,
  },
  data() {
    return {
      options: {
        licenseKey: "1806D743-4E124AA5-92F132F2-321A5FA4",
        // menu: "#menu",
        // sectionsColor: ["#41b883", "#ff5f45", "#0798ec"],
        // parallaxKey: "a29udHJvbGxmZWxkLm5ldF9kUXBjR0Z5WVd4c1lYZz1NOEE=",
        // parallax: true,
        // parallaxOptions: { percentage: 62 },
        fadingEffect: true,
        fadingEffectKey: "a29udHJvbGxmZWxkLm5ldF83WVNabUZrYVc1blJXWm1aV04wQUF2",
        paddingTop: "2.5rem",
        afterLoad: this.afterLoad,
        onLeave: this.onLeave,
      },
      svg0423AVal: 0,
      svg0423BVal: 0,
      svg01GlobeAnimatedVal: 0,
      svg01ClocksFullsizeAnaVal: 0,
      svg01ClocksFullsizeKofiVal: 0,
      svg01ClocksFullsizeMilaVal: 0,
    };
  },
  methods: {
    goToLastSection() {
      this.$refs.fullpage.api.moveTo("ana-section-37");
    },
    goToSectionFromParam(index) {
      if (index) {
        this.$refs.fullpage.api.moveTo(index);
      }
    },
    updateBodyBackground(bgColorClass) {
      this.removeBackgroundClassFromBody();
      document.body.classList.add(bgColorClass);
    },
    removeBackgroundClassFromBody() {
      document.body.classList.forEach(function (val, index, listObj) {
        if (val.startsWith("bg-")) {
          listObj.remove(val);
        }
      });
    },
    getBgClassFromSectionWithIndex(index) {
      return this.$children[0].$children[index].$options.propsData
        .wrapperClasses[0];
    },
    /* eslint-disable no-unused-vars */
    afterLoad(origin, destination, direction) {
      // console.log("'after load' origin:", origin);
      // console.log("'after load' destination:", destination);
      // console.log("'after load' direction:", direction);

      let originSectionIndex = origin.index + 1;
      let destinationSectionIndex = destination.index + 1;
      console.log("'originSectionIndex:", originSectionIndex);
      console.log("'destinationSectionIndex:", destinationSectionIndex);

      // If is intial load, do not replace section query.
      if (direction) {
        this.$router
          .replace({ query: { section: destinationSectionIndex } })
          .catch((err) => {});
      }

      switch (destinationSectionIndex) {
        case 2:
          this.$refs["audio01"].playMedia();
          break;
        case 4:
          this.$refs["audio03"].playMedia();
          break;

        default:
          break;
      }

      switch (originSectionIndex) {
        case 2:
          this.$refs["audio01"].pauseMedia();
          break;
        case 4:
          this.$refs["audio03"].pauseMedia();
          break;

        default:
          break;
      }
    },
    onLeave(origin, destination, direction) {
      let destinationSectionIndex = destination.index + 1;
      this.updateBodyBackground(
        this.getBgClassFromSectionWithIndex(destination.index)
      );

      switch (destinationSectionIndex) {
        case 1:
          // this.$refs["audio11111"].playMedia();
          break;
        case 2:
          this.svg01ClocksFullsizeAnaVal = !this.svg01ClocksFullsizeAnaVal;
          this.$refs["svg01clocksfullsizeana"].animate();
          break;
        case 3:
          this.svg01ClocksFullsizeKofiVal = !this.svg01ClocksFullsizeKofiVal;
          this.$refs["svg01clocksfullsizekofi"].animate();
          break;
        case 4:
          this.svg01ClocksFullsizeMilaVal = !this.svg01ClocksFullsizeMilaVal;
          this.$refs["svg01clocksfullsizemila"].animate();
          break;
        case 5:
          this.svg01GlobeAnimatedVal = !this.svg01GlobeAnimatedVal;
          this.$refs["svg01globeanimated"].animate();
          break;
        case 17:
          this.svg0423AVal = !this.svg0423AVal;
          this.$refs["svg0423A"].animate();
          // this.$refs["video11117"].playMedia();
          break;
        case 18:
          this.svg0423BVal = !this.svg0423BVal;
          this.$refs["svg0423B"].animate();
          // this.$refs["video11117"].playMedia();
          break;

        default:
          break;
      }
    },
    /* eslint-enable no-unused-vars */
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "section")) {
      this.goToSectionFromParam(this.$route.query.section);
    }
    let section = this.$route.query.section ? this.$route.query.section + 1 : 1;
    console.log("created section" + section);
    this.updateBodyBackground(this.getBgClassFromSectionWithIndex(section));
  },
  beforeRouteUpdate(to, from, next) {
    let sectionTo;
    let sectionFrom;
    if (Object.prototype.hasOwnProperty.call(to.query, "section")) {
      sectionTo = parseInt(to.query.section);
    }
    if (Object.prototype.hasOwnProperty.call(from.query, "section")) {
      sectionFrom = parseInt(from.query.section);
    }
    if (sectionTo || sectionFrom) {
      if (sectionTo != sectionFrom) {
        console.log("sectionTo", sectionTo);
        if (!sectionTo) {
          sectionTo = 1;
        }
        this.goToSectionFromParam(sectionTo);
      }
    }
    next();
  },
};
</script>

<style>
.section .fp-bg {
  background-size: cover;
  background-position: center 80%;
}
.section[data-id="story1-1.1.1-1"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_1.svg");
}
.section[data-id="story1-1.1.1-2"] .fp-bg {
  background-image: url("~@/assets/images/stories/story_1/Story_1_1.1.1/Story1_1.1.1_2.svg");
}
</style>
